.MuiPickersDay-root.Mui-selected,
.MuiPickersDay-root.Mui-selected:hover {
    background: #494949;
}

@media only screen and (max-width: 600px){
    .MuiMenu-paper {
        width: 100%;
        max-width: 90%;
    }
}