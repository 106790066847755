.MuiMenu-paper {
    margin-top: 22px;
    max-width: 519px;
    width: 519px;
}

.MuiFormGroup-row {
    gap: 0 124px;
}

.responsibilities {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
