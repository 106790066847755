* {
    box-sizing: border-box;
}
/* #root {
    max-width: 1600px;
    margin: 0 auto;
} */
.global_loader {
    display: none;
}

.loading .global_loader {
    display: block;
}
